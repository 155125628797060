.fieldset{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
    
    border: 1px solid #E1E3E8;
    padding: 14px;
    border-radius: 6px;
    font-size: 14px;
    color: #2b2d42;
    
    transition: border linear 160ms;
    background-color: #fff;
}

.fieldset i{
    display: inline-block;
    padding-left: 16px;
    font-size: 20px;
}

.campos{
    border: none !important;
    width: 100%;
    font-size: 14px;
    color: #2b2d42;
    font-weight: 500;
    background-color: transparent;
}


.fieldset:focus-within {
    background: #fff;
    border: 1px solid #60C7EC;
  }

.campos::placeholder{
    color: #6d819c94;
    font-weight: 500;
}