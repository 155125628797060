@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap');
@import './animate.min.css';

html,
body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-color: #f6f6fa;
    font-family: 'Rubik', sans-serif;
    scroll-behavior: smooth;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1{
    color: #2b2d42;
    font-size: 1.7em;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    align-content: center;
    line-height: 100%;
}

h1 small{
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    color: #6D819C;
    font-weight: 500;
    margin-left: 10px;
}

h1 small b{
    color: #60C7EC;
}

h3, h4{
    color: #2b2d42;
    font-size: 1.2em;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    align-content: center;
    padding-bottom: 10px;
    justify-content: space-between;
}

h4{
    font-size: 1.0em;
    border: none;
}

h3 span{
    font-size: 12px;
    color: #6D819C;
    font-weight: 400;
    letter-spacing: normal;
}

.container{
    padding: 32px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 #D9D9E1;
}

.container p{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
}

.areaInfo, .areaInfoForm{
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.areaInfo label, .areaInfoForm label{
    display: inline-block;
    margin-bottom: 10px;
    font-size: 0.7em;
    color: #2b2d42ce;
    font-weight: 500;
}

.areaInfo .container{
    width: 100%;
    min-width: 300px;
}

.areaInfo > * + * { margin-left: 20px; margin-bottom: 20px;}

.informacoes .container{
    margin-bottom: 20px;
}

.areaInfo > .container{
    margin-bottom: 20px;
}

.cardFull{
    width: 100%;
    margin-bottom: 20px;
}

th{
    font-weight: 600 !important;
    font-family: 'Rubik', sans-serif !important;
    color: #2b2d42 !important;
    font-size: 12px !important;
}
td{
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px !important;
}

td a{
    font-family: 'Rubik', sans-serif !important;
    color: #2b2d42;
    text-decoration: underline;
}
td a:hover{
    color: #60C7EC;
}

.inputGrid{
    display: flex;
    gap: 20px;
}

.uk-table-striped tbody tr:nth-of-type(odd), .uk-table-striped>tr:nth-of-type(odd) {
    background: #6d819c10;
    border-top: 1px solid #6d819c1f;
    border-bottom: 1px solid #6d819c1f;
}
.trInfo{
    display: flex;
}
.trInfo .avatar, .trInfo .avatarIniciais{
    margin-right: 8px;
}

.badge{
    background-color: #6D819C;
    border-radius: 20px;
    color: #fff;
    padding: 2px 10px;
    text-align: center;
}

.pagination{
    list-style: none;
    display: flex;
    padding: 0;
    margin: 30px 0;
    align-self: flex-end;

}

.pagination a{
    font-size: 16px;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    padding: 1px 8px;
    color: #6D819C;
    border-radius: 4px;
    transition: all linear 160ms;
    text-decoration: none !important;
    background-color: #fff;
}

.pagination a:hover{
    color: #2b2d42;
}

.pagination .active a{
    color: #fff !important;
    background-color: #60C7EC;
}
.pagination .active{
    background-color: #60C7EC;
    border-radius: 4px;
    opacity: 1;
}


.modal{
    top: 0;
    left: 0;
    background-color: #2b2d426b;
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    padding-top: 80px;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
}

.modal .container{
    width: 100%;
    max-width: 540px;
    max-height: 85vh;
    overflow-y: auto;
}

.modal .container h3{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.areaEmpty{
    border: 2px dashed #2b2d4233;
    background-color: #2b2d420e;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.camposH{
    display: flex;
    align-items: center;
    align-content: center;
}

.camposH > * + * { margin-left: 20px;}


.itemCard{
    display: flex;
    padding: 20px 0;
    margin-right: 20px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    transition: all linear 160ms;
}


.itemCard small{
    color: #6D819C;
    font-size: 12px;
}

.itemCard i{
    display: inline-block;
    font-size: 24px;
    margin-bottom: 8px;
}

.itemCard:hover{
    border-color: #2b2d42;
}
.iconCard{
    margin-bottom: 10px;
}

.cardMidias{
    display: flex;
    flex-wrap: wrap;
}

label{
    display: inline-block;
    margin-bottom: 10px;
    font-size: 0.7em;
    color: #2b2d42ce;
    font-weight: 400;
}

.areaCampo{
    margin-bottom: 16px;
}

.cardsOptions{
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    padding:20px;
    background-color: rgba(0, 0, 0, 0.03);
    border:1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    gap:10px
}

/* .cardsOptions > * + * { margin-left: 20px;} */

.cardOption{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 #D9D9E1;
    cursor: pointer;
    transition: all linear 160ms;
    min-width: 140px;
}
.cardOption:hover{
    transform: scale(1.05);
}
.cardOption h4{
    font-size: 13px;
    display: inline-block;
    margin: 0;
    padding: 0;
    color: #2b2d42;
}

.cardActive{
    background-color: #60C7EC;
}

.cardActive h4{
    color: #fff;
}

.cardActive i{
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.cardOption i{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    background-color: #6D819C;
    padding: 6px;
    border-radius: 100%;
    margin-bottom: 10px;
}

.areaOptions{
    padding:20px;
    background-color: rgba(0, 0, 0, 0.03);
    border:1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px
}

.areaOptions ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.areaOptions ul li{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    font-size: 13px;
    color: #2b2d42;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    transition: all linear 160ms;
}

.areaOptions ul li:hover{
    border: 1px solid #6d819c7a
}

.areaOptions ul li i{
    display: inline-block;
    font-size: 16px;
}
.iconCorrect{
    color: #06d6a0;
    margin-left: 10px;
}

.iconRemove{
    color: #ef233c;
    cursor: pointer;
    transition: all linear 160ms;
    opacity: 0.6;
    font-size: 18px;
}

.iconRemove:hover{
    opacity: 1;
}

.iconDefault{
    color: #6D819C;
    cursor: pointer;
    transition: all linear 160ms;
    opacity: 0.6;
    font-size: 18px;
}

.iconDefault:hover{
    opacity: 1;
}

label{
    display: inline-block;
    margin-bottom: 10px;
    font-size: 13px;
    color: #2b2d42ce;
    font-weight: 400;
}
.camposStart{
    align-items: center;
    align-content: center;
}
.camposStart button{
    padding: 14px;
    margin-top: 12px;
    width: 140px;
}


.campos:focus{
    border: 1px solid #60C7EC;
}

.cardProfile{
    display: flex;
    gap: 10px;
}

.cardProfile span{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cardProfile span h4, .cardProfile span small{
    margin: 0;
    padding: 0;
    line-height: 100%;
}

.success{
    color: #06d6a0;
}

.error{
    color: #ef233c;
}

.areaFilter{
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.021);
    margin-bottom: 20px; 
    border-radius: 6px;
    padding: 10px 20px;
    border: 1px solid #6d819c18;
}
.areaFilter input, .areaFilter select{
    margin-bottom: 10px;
}